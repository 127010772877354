import React from 'react'
import styled from '@emotion/styled'

// Components
import Layout from '../components/layout'
import SEO from '../components/organisms/seo'
import Masthead from '../components/organisms/masthead'
import ImgLink58thStreetCampus from '../components/images/links/portfolio/img-link-58th-street-campus';
import Paragraph1 from '../components/atoms/paragraph-1'
import Header1 from '../components/atoms/header-1'
import Header2 from '../components/atoms/header-2'
import Address1 from '../components/atoms/address-1'
import Subtitle1 from '../components/atoms/subtitle-1';
import List1 from '../components/atoms/list-1';
import Item1 from '../components/atoms/item-1';
import List2 from '../components/atoms/list-2';
import Link5 from '../components/atoms/link-5';
import ImgMastheadHome from '../components/images/masthead/img-masthead-home';
import Link1 from '../components/atoms/link-1';

// Styles
const Container  = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 150px 30px;
`

// Data
const dataMasthead = {
  mastheadImage: <ImgMastheadHome/>,
  tagImage: <ImgLink58thStreetCampus/>,
  tagTitle: 'Photo Credit',
}

const PhotoCreditPage = () => (
  <Layout>
    <SEO title="Photo Credit" keywords={[`landscaping`, `landscape architecture`, `catharine ann farnen llc`, `pennsylvania landscaping`, `photo credit`]} />
    <Masthead 
      mastheadImage={dataMasthead.mastheadImage} 
      tagImage={dataMasthead.tagImage}
      tagTitle={dataMasthead.tagTitle}
      tagType={dataMasthead.tagType}
    />
    <Container>
      <Header1>Photo Credit</Header1>
      <Paragraph1>Photography credit goes to <b>Valerie Walter</b> for the following projects:</Paragraph1>
      <List1>
        <Item1>Camilla Hall</Item1>
        <Item1>Rosemont</Item1>
        <Item1>Outdoor Dining</Item1>
        <Item1>Pipersville Meadow</Item1>
        <Item1>58th Street Campus</Item1>
      </List1>
      <Paragraph1><b>Valerie Walter</b> can be found through her website at <Link1 to='http://www.valeriewalter.com/' fontSize='1em' color='#70926e' text='www.valeriewalter.com'/></Paragraph1>
    </Container>
  </Layout>
)

export default PhotoCreditPage